
.big-container{
	width: 300px;
	height:300px;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* .spin-container{
    /* display: none; */
	/* width: 300px;
	height:300px;
	background-color: #ccc;
	border-radius:50%;
	border:15px solid #dde;
	position: fixed;
	overflow: hidden;
	transition: 5s; 
} */

.spin-container div{
	height:50%;
	width: 120px;
	position: absolute;
	clip-path: polygon(100% 0 , 50% 100% , 0 0 );
	transform:translateX(-50%);
	transform-origin:bottom;
	text-align:center;
	display:flex;
	align-items: center;
	justify-content: center;
	font-size:20px;
	font-weight:bold;
	font-family:sans-serif;
	color:#fff;
	left:74px;
	/* left:87px; */
}

.spin-container .one{
	background-color: #3f51b5;
	left:135px;
}
.spin-container .two{
	background-color: #ff9800;
	transform: rotate(40deg);
}
.spin-container .three{
	background-color: #e91e63;
	transform:rotate(80deg);
}
.spin-container .four{
	background-color: #4caf50;
	transform: rotate(120deg);
}
.spin-container .five{
	background-color: #009688;
	transform: rotate(160deg);
}
.spin-container .six{
	background-color: #795548;
	transform: rotate(200deg);
}
.spin-container .seven{
	background-color: #9c27b0;
	transform: rotate(240deg);
}
.spin-container .eight{
	background-color: #f44336;
	transform: rotate(280deg);
}
.spin-container .nine{
	background-color: #000;
	transform: rotate(320deg);
}

.arrow{
	/* display: none; */
	position: fixed;
	height: 80px;
	/* width: 0px; */
    /* margin-bottom: 300px; */
	/* top:20%; */
	/* left:50%; */
	clip-path: polygon(0 0, 50% 100%, 100% 0);
	/* transform: translateX(-50%); */
	color:#fff;
	/* background-color: #fff; */
	z-index: 1;
}

/* .arrow::before{
	content:"\1F817";
	font-size:50px;
} */

.spin{
	/* display: none; */
	position: fixed;
	/* top:50%; */
	/* left:50%; */
	/* transform:translate(-50%,-50%); */
	z-index:10;
	text-align: center;
	background-color: #e2e2e2;
	text-transform: uppercase;
	border:4px solid #fff;
	font-weight:bold;
	/* font-size:10px; */
	color:#a2a2a2;
	width: 40px;
	height:40px;
	font-family: sans-serif;
	border-radius:50%;
	cursor: pointer;
	outline:none;
	/* padding: 1px; */
	letter-spacing: 1px;
}