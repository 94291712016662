html{
    scroll-behavior: smooth;
    /* margin-bottom: ; */
}

.parent {
    display: grid;
    grid-gap: 25px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    /* grid-column-gap: 0px;
    grid-row-gap: 0px; */
    }
    
    .div1 { grid-area: 1 / 1 / 2 / 2; }
    .div2 { grid-area: 1 / 2 / 2 / 3; }
    .div3 { grid-area: 1 / 3 / 2 / 4; }
    .div4 { grid-area: 2 / 1 / 3 / 2; }
    .div5 { grid-area: 2 / 2 / 3 / 3; }
    .div6 { grid-area: 2 / 3 / 3 / 4; }